import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import AssemblyCard from "./AssemblyCard"
import { format } from "date-fns"
import { requestTestReports, requestStops, requestStopTesters, updateTesters} from "../../../../actions/addressApp"
import { 
	requestStopDetails, 
	requestContact, 
	updateTester, 
	requestStop, 
	updateTimeFrame, 
	updateDate,
	updateStopDetails,
	cancelStop
	  } from "../../../../actions/servicesReport"
import { requestTesterNames } from "../../../../actions/dispatch"

export default function StopCard(props) {
	const addressID = useSelector((state) => state.addressID)
	const testerList = useSelector((state) => state.testers);
	const [reportlist, setReportList] = useState([]); 
	const [myStop, setMyStop] = useState(null)
	const [loader, setLoader] = useState(true)
	const [startTime, setStartTime] = useState(null)
	const [endTime, setEndTime] = useState(null)
	const [show, setShow] = useState(false)
	const [showTesters, setShowTesters] = useState(false)
	const [testerLoader, setTesterLoader] = useState(false)
	const [stopLoader, setStopLoader] = useState(false)

	const [time, setTime] = useState(null)
	const [wind, setWind] = useState(null);
	const [requestor, setRequestor] = useState(null)
	const [phone, setPhone] = useState(null)
	const [stopDate, setStopDate] = useState(null)
	const [myComment, setMyComment] = useState("")
	const [tList, setTList] = useState([]); 
	const [disabled, setDisabled] = useState(false)
	const [cancelLoader, setCancelLoader] = useState(false)
	const dispatch = useDispatch()
	const [canceling,  setCanceling] = useState(false)

	const updateDetails  = ()=>{
		let obj = {};
		obj.requestor = requestor; 
		obj.phone = phone; 
		obj.note = myComment; 
		updateStopDetails(props.stop.id , obj).then( (data, err) =>{
			requestStop(props.stop.id).then((data, err)=>{
				setMyStop(data)
			})

		})
	}


	// request stop on rendering 
	useEffect(() => {
		setStopLoader(true)
		requestStop(props.stop.id).then((data, err)=>{
			console.log("This is my stop: ", data)
			setMyStop(data)
			setStopLoader(false)
		})
	}, [])

	

	const requestStopTT = () => {
		setTesterLoader(true)
		requestStopTesters(props.stop.id).then((data, err) => {
			console.log("These are the testers: ", data)
			setTList(data)
			setTesterLoader(false)
		})
	}



	//request tester names on rendering
	useEffect( () =>{
		setShowTesters(false)
		dispatch( requestTesterNames() ).then((data, err) =>{
			setShowTesters(true)
		})
	}, [])

	//update the scheduled testers when the list length is more than zero
	useEffect(() => {
		if (tList.length > 0) {
			updateTesters(props.stop.id, tList).then((data, err) => {
				console.log("Done updating list")
			})
		}
	}, [tList])

	//updates stop date, then updates the address stop list
	useEffect(()=>{
		if (stopDate != null) {
			if (stopDate != format(new Date(myStop.date), "yyyy-MM-dd")) {
				dispatch(updateDate(props.stop.id, stopDate)).then((data, err) => {
					dispatch(requestStops(addressID)).then((data, err) => {

					})
				})
			}
			

		}
	}, [stopDate])



	// when MyStop is not null, updates the values for rendering
	useEffect(() => {
		if (myStop != null) {
			setStartTime(myStop.startTime)
			setEndTime(myStop.endTime)

			if (myStop.startTime == myStop.endTime) {
				setTime(true)
				setWind(false)
			} else if (myStop.startTime != '08:00' || myStop.endTime != '16:00') {
				setTime(false)
				setWind(true)
			} else {
				setTime(false)
				setWind(false)
			}
			setStopDate(format(new Date(myStop.date), "yyyy-MM-dd"))
			setMyComment(myStop.comment)
			setRequestor(myStop.requestor)
			setPhone(myStop.phone)
		}

	}, [myStop])

	//determine edit status in case of cancelled stop 
	useEffect(() => {
		if (myStop != null) {
			if (myStop.status == "CANCELLED") {
				setDisabled(true)
			}
		}
	}, [myStop])

	//update start and end times
	useEffect(() => {
		if (startTime != null && endTime != null) {
			
			if (startTime != myStop.startTime || endTime != myStop.endTime) {

				dispatch(updateTimeFrame(props.stop.id, startTime, endTime)).then((data, err) => {
					requestStop(props.stop.id).then((data, err) => {
						setMyStop(data)
					})
				})
			}

		}
	}, [startTime, endTime])

	// returns true if tester is in the list of scheduled testers
	const isChecked = (id) =>{
		for(let i = 0; i < tList.length; i++){
			if(tList[i].id == id){
				return true
			}
		}
		return false
	}

	//return the index of the tester with the given id
	const getIndex = (id) => {
		for(let i = 0; i < tList.length; i++){
			if(tList[i].id == id){
				console.log(`Returning true for ${tList[i].id}, ${id}`)
				return i
			}
		}
		return -1
	}

	const renderCheckTesters = testerList.map( (tt) =>{
		if( tt.tester){
			return(
				<Col>
					<Form.Check 
						type="checkbox" 
						label= {tt.tester_name}
						checked = {  isChecked(tt.id) }
						disabled={ disabled}
					
						onClick = {
							(event)=>{
								if(event.target.checked){
									if(!isChecked(tt.id) ){
										let newlist = [...tList]; 
										newlist.push({id: tt.id}); 
										setTList(newlist)
									}
										
								}else{
									if( isChecked( tt.id) ){
										//find index of
										let myIndex = getIndex(tt.id); 
										if( myIndex > - 1){
											console.log("This is the index: ", myIndex)
											let removed = tList.splice(myIndex, 1)
											console.log("removed: ", removed)
											let newlist = [...tList]
											setTList(newlist)
										}
											
									}
								}	
							}
						}
					/>
				</Col>
			)
		}else{
			return(
				<> </>
			)
		}
		
	})

	const getReports = () =>{
		setLoader(true)
		requestTestReports(props.stop.id).then((data, err) =>{
			setReportList(data)
			setLoader(false)
		})
	}

	const renderAssemblies =  reportlist.map((report) =>{
		return(
				<AssemblyCard report = {report} />
		
		)
	})

	const loadBody = () => {
		getReports()
		requestStopTT()

	}

	return(
		<>

			<Accordion.Item eventKey={props.index} >

			<Accordion.Header>
				<Container >
					<Row>
						<Col>
							<p> <small>SCHEDULED FOR</small><br/> {format ( new Date( props.stop.date ),   "LLLL do, yyyy")}</p>
						</Col>
						<Col>
							<p> <small>STATUS</small> <br />{props.stop.status}</p>
						</Col>
					
					</Row>
				</Container>
				
			</Accordion.Header>
				<Accordion.Body onEnter={ loadBody}>
					{
						cancelLoader && loader ?
							<Spinner />
							:
							<Container >
								{
									stopLoader ?
										<Spinner />
									:

										<Row>
											<Col sm md lg={3}>

												<Container>
													<Form.Label> Stop Date </Form.Label>
													<Form.Control
														value={stopDate}
														size="sm"
														type="date"
														onChange={
															(event) => setStopDate(event.target.value)
														}

														disabled={disabled}
													/>
												</Container>
											</Col>
											<Col>
												<Container>
													<Row>
														<Col>
															<Form.Label> Appointment time</Form.Label>
														</Col>
													</Row>
													<Row>
														<Col sm md lg={3}>
															<Form.Check type="radio" label="Any" name="tt"
																disabled={disabled}
																checked={!time && !wind}
																onChange={(event) => {
																	setTime(!event.target.checked)
																	setWind(!event.target.checked)
																	setStartTime("08:00")
																	setEndTime("16:00")

																}} />

															<Form.Check type="radio"
																label="Time"
																name="tt"
																checked={time}
																disabled={disabled}
																onChange={(event) => {
																	setTime(event.target.checked)
																	setWind(!event.target.checked)
																}} />

															<Form.Check type="radio"
																disabled={disabled}
																label="Window" name="tt" checked={wind} onChange={(event) => {
																	setWind(event.target.checked)
																	setTime(!event.target.checked)
																}} />
														</Col>
														<Col>
															{
																wind ?
																	<Container>
																		<Row>
																			<Col>
																				<Form.Label>Start Time </Form.Label>
																				<Form.Control
																					disabled={disabled}
																					type="time"
																					value={startTime}
																					onChange={(event) => setStartTime(event.target.value)} />
																			</Col>
																			<Col>
																				<Form.Label> End Time </Form.Label>
																				<Form.Control
																					disabled={disabled}
																					type="time"
																					value={endTime} onChange={(event) => setEndTime(event.target.value)} />
																			</Col>
																		</Row>
																	</Container>
																	:
																	<> </>

															}

															{
																time ?
																	<Form.Control type="time" value={startTime} onChange={(event) => {
																		setStartTime(event.target.value)
																		setEndTime(event.target.value)
																	}} />
																	:
																	<> </>
															}
														</Col>
													</Row>

												</Container>
											</Col>

										</Row>
								}
							
								<hr />
								<Row>
									<Col sm md lg={9}>
										<h6>Assigned To: </h6>
									</Col>

								</Row>
								<Row sm md lg={4}>

									{
										testerLoader ?
											<Spinner />
											:
											<>
												{renderCheckTesters}
											</>

									}
									
								</Row>
								<hr/>
								<Row>
									<Col>
										<FloatingLabel label = "Note">
											<Form.Control
												size="sm"
												as="textarea"
												placeholder=""
												value={myComment}
												onChange = {
													(event) => setMyComment( event.target.value)
												}
												onBlur = { updateDetails}
									
											/>
										</FloatingLabel>
									</Col>
									</Row>
									<Row>
										<Col>
											<FloatingLabel label = "Requestor" size= "sm">
												<Form.Control
												size="sm"
												placeholder=""
												value={requestor}
												onChange = {
													(event) => setRequestor( event.target.value)
												}
												onBlur = { updateDetails}

												/>
											</FloatingLabel>
										</Col>
										<Col>
											<FloatingLabel label = "Phone #" size= "sm">
													<Form.Control
													size="sm"
													placeholder=""
													value={phone}
													onChange = {
														(event) => setPhone(event.target.value)
													}
													onBlur = {updateDetails}
													/>
											</FloatingLabel>
										
										</Col>
									
								</Row>
								<Row>

									<Col className="scrollReports">
										<hr />
										<Table size="sm" bordered responsive>
											<thead>
												<tr>
													<th> Report ID</th>
													<th> Serial #</th>
													<th> Int. Results</th>
													<th> Rpr. Results</th>
													<th> Status </th>
													<th>  Water Sent</th>
													<th>  Client Sent </th>
													<th> </th>
												</tr>
											</thead>
											<tbody >

												{
													loader ?
														<> loading reports</>
														:
														<> {renderAssemblies}	</>

												}

											</tbody>
										</Table>
									</Col>
								</Row>
								<Row>

									<Col>
										<hr />
										<p><small>REQUESTED BY: <br />{props.stop.requestor} </small> </p>
									</Col>

									<Col sm md lg={4}>
										<hr />
										{
											myStop ?
												<p><small>SCHEDULED BY: <br />{myStop.myScheduler}</small></p>
												:
												<>
												</>

										}


									</Col>
									<Col>
										<hr />
									
											<Button
											variant="danger"
											size="sm"
											disabled={disabled}
											onClick={() => {
												setCancelLoader(true)
												cancelStop(props.stop.id).then((data, err) => {
													console.log("Stop canceled")
													setCanceling(true)
													dispatch ( requestStops(addressID)).then((data, err) =>{
														requestStop(props.stop.id).then((data, err) => {
														setMyStop(data)
														setCancelLoader(false)
														setCanceling(false)
														})
													})
													
												})
											}}
										> 
											{
												canceling ?
													<Spinner />
												: 
													<>Cancel stop</>
											}
											 </Button>
											

										

										
									</Col>
									<hr />
								</Row>
							</Container>

					}
				
			</Accordion.Body>
		
				
		
		</Accordion.Item>
		</>
	)
}