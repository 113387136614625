import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import { logout } from "../actions/session.js"; 
import BillingApplication from "./apps/billing/BillingApplication"
import WaterDeliveryApplication from "./apps/testReports/WaterDeliveryApplication"; 
import SchedulingApplication from "./apps/scheduling/SchedulingApplication"
import CustomerApplication from "./apps/customer/CustomerApplication"
import HomeApplication from "./apps/home/HomeApplication"
import Dispatch from "./apps/dispatch/Dispatch"
import ServicesReport from "./apps/servicesreport/ServicesReport"
import AccountsApps from "./apps/accounts/AccountsApps"
import GaugesApp from "./apps/accounts/GaugesApp"
import TestersApp from "./apps/accounts/TestersApp"
import Rounds from "./apps/rounds/Rounds"


export default function Navigation(){
	const [show, setShow] = useState(false); 
	const [key, setKey] = useState("HOME")
	const [showWaiting, setShowWaiting] = useState(false)
	const [expand, setExpand] = useState(false)
	const dispatch = useDispatch();

	function handleSelect(event){
		switch(event.toUpperCase()){
			case "HOME": 
				setDisplayApp(<HomeApplication />)
				break; 
			case "INVOICING":
				setDisplayApp(<BillingApplication />)
				break; 
			case"TESTREPORTS":
				setDisplayApp(<WaterDeliveryApplication />)
				break; 
			case "SCHEDULE": 
				setDisplayApp(<SchedulingApplication />)
				break; 
			case "CUSTOMER": 
				setDisplayApp(<CustomerApplication />)
				break; 
			case "DISPATCH": 
				setDisplayApp(<Dispatch />)
				break; 
			case "SERVICESREPORT": 
				setDisplayApp(<ServicesReport />)
				break; 

			case "ACCOUNTS": 
				setDisplayApp(<AccountsApps />)
				break; 
			case "GAUGES": 
				setDisplayApp(<GaugesApp />)
				break; 
			case "TESTERS": 
				setDisplayApp(<TestersApp />)
				break; 
			case "ROUNDS": 
				setDisplayApp(<Rounds />)
				break; 

			default: 
				break; 
		}
		setKey(event.toUpperCase())
		
	}
	const handleClose = () =>setShow(false)
	const [displayApp, setDisplayApp] = useState(<HomeApplication />)
		function logmeout(){
		setShowWaiting(true)
		dispatch( logout()).then( (data, err)=>{
			console.log("logged out!")
			setShowWaiting(false)
		})
	}

	const [ratio1, setRatio1] = useState(1); 
	const [ratio2, setRatio2] = useState(3)

	useEffect(()=>{
		if(show){
			setRatio1(3)
			setRatio2(7)
		}
	}, [show])
	return(
		<Container fluid >
			<Row>
				<Col>
					  <Navbar key={expand} expand={expand} variant="light" bg="dark" data-bs-theme="dark" collapseOnSelect >
				          <Container >
				          	<Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
				            <Navbar.Brand href="#"  id = "amazon-branding">American Backflow & Plumbing, Inc.</Navbar.Brand>
				            <div >
								<CustomerApplication />
							</div>
				            
				            <Navbar.Offcanvas
				              id={`offcanvasNavbar-expand-${expand}`}
				              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
				              placement="start"
				            >
				              <Offcanvas.Header closeButton>
				                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
				                  Menu
				                </Offcanvas.Title>
				              </Offcanvas.Header>
				              <Offcanvas.Body>
				                <Nav 
									variant="underline" 
									activeKey={key} 
									onSelect={handleSelect} 
									className="flex-column"
									>
									
									
									<Nav.Item>
											<Nav.Link eventKey="HOME"  >Home</Nav.Link> 
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="SERVICESREPORT"  > Services </Nav.Link> 
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="INVOICING">Billing & Invoicing</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="TESTREPORTS" > Test Reports</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="GAUGES" > Gauges</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="TESTERS" >Testers</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="ROUNDS" disabled> Rounds</Nav.Link>
										</Nav.Item>
									<hr/>
									<p>User Name</p>
									<Button variant="secondary" onClick = {()=> logmeout()} size="sm"> Log  out </Button>
								</Nav>
				              </Offcanvas.Body>
				            </Navbar.Offcanvas>
				          </Container>
				        </Navbar>
			    </Col>
		    </Row>
		   <Row sm md lg ={1}>
		    	<Col>	
		    		{displayApp}
		    	</Col>
		    </Row>
		</Container>
	)
}	