import Alert from 'react-bootstrap/Alert';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';
import Offcanvas from 'react-bootstrap/Offcanvas';
import CardGroup from 'react-bootstrap/CardGroup';
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import { getTotalsList  } from "../../../actions/home.js"
import { getTestersStops , getStops} from "../../../actions/scheduling.js"
import { requestList } from "../../../actions/rounds.js"
import {format } from "date-fns"
import AppFilters from "./AppFilters"
import RoundCard from "./RoundCard"
import PaginateMe from "./PaginateMe"

export default function Rounds(){
	const mylist = useSelector((state) => state.rounds_list)
	const currentMonth = useSelector((state) => state.rounds_month)
	const currentDate = useSelector((state) => state.rounds_date)
	const currentCount = useSelector((state) => state.rounds_count)
	
	const [loader, setLoader] = useState(); 
	const dispatch = useDispatch(); 

	useEffect(()=>{
		setLoader(true)
		dispatch( requestList(currentDate, currentMonth, currentCount) ).then((data, err) =>{
			setLoader(false)
		})
	}, [currentMonth, currentDate, currentCount])

	const renderCards = mylist.map( (cc) => 
			<Col>
				<RoundCard />
			</Col>
		)

	return(
		<Container>
			
			<Row>
				<Col sm md lg  = {10}>
				<hr/>
					<h5> Rounds</h5>
				</Col>
				<Col>
					<hr/>
					<p> Showing X of Y </p>
				</Col>
			</Row>
			
			<Row>
				<Col>
					<hr/>
					<AppFilters />
				</Col>
			</Row>
			<hr/>
			<Row sm md lg = {3}>
					{
						loader ?
							<Spinner />
						: 
							<>
								{renderCards}
							</>

					}
			</Row>
			<Row>
				<PaginateMe />
			</Row>
		</Container>
	)
}