import Container from 'react-bootstrap/Container';
import Pagination from 'react-bootstrap/Pagination';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";

export default function PaginateMe(){


	let active = 2;
	let items = [];

	for (let number = 1; number <= 5; number++) {
	  items.push(
	    <Pagination.Item key={number} active={number === active}>
	      {number}
	    </Pagination.Item>,
	  );
	}

	return(
		<>
			<Pagination>
				{items}
			</Pagination> 
		</>
	)
}