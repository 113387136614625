export const requestList = (dueDate, month, count) => (
  fetch("/backflow/rounds?" + new URLSearchParams({
    dueDate: dueDate, 
    month: month, 
    count: count
  }), {
      method: "GET",
      headers: {
        "Content-Type": "application/json", 
        "Cache-Control": "no-store, no-cache"
      }
  })
)

export const requestFollowUpDetails = (followID) => (
  fetch("/backflow/rounds/details?" + new URLSearchParams({
      followID :  followID
    }), {
        method: "GET",
        headers: {
          "Content-Type": "application/json", 
          "Cache-Control": "no-store, no-cache"
        }
    })
)

export const requestAddressDetails = (addressID) => (
  fetch("/backflow/rounds/address?" + new URLSearchParams({
      addressID: addressID
    }), {
        method: "GET",
        headers: {
          "Content-Type": "application/json", 
          "Cache-Control": "no-store, no-cache"
        }
    })
)

export const requestContactDetails = (addressID) => (
  fetch("/backflow/rounds/contact?" + new URLSearchParams({
      addressID: addressID
    }), {
        method: "GET",
        headers: {
          "Content-Type": "application/json", 
          "Cache-Control": "no-store, no-cache"
        }
    })
)
export const requestBillingDetails = (addressID) => (
  fetch("/backflow/rounds/billing?" + new URLSearchParams({
      addressID: addressID
    }), {
        method: "GET",
        headers: {
          "Content-Type": "application/json", 
          "Cache-Control": "no-store, no-cache"
        }
    })
)

export const setForFollowUp = (followID, obj) => (
  fetch("/backflow/rounds/follow", {
        method: "PUT",
        body: JSON.stringify({ followID: followID, obj: obj}),
        headers: {
          "Content-Type": "application/json", 
          "Cache-Control": "no-store, no-cache"
        }
    })
)
export const setForNoFollow = (followID, obj) => (
  fetch("/backflow/rounds/no_follow", {
        method: "PUT",
        body: JSON.stringify({ followID: followID, obj:obj }),
        headers: {
          "Content-Type": "application/json", 
          "Cache-Control": "no-store, no-cache"
        }
    })
)






