import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import { determineID, requestStops } from "../../../actions/addressApp.js"
import NewAddressForm from "./servicesTab/NewAddressForm"
import Summary from "./Summary"

import ServicesTab from "./servicesTab/ServicesTab"
import AccountTab from "./accountTab/AccountTab"
import AddressesTab from "./addressesTab/AddressesTab"
import InvoiceTab from "./invoiceTab/InvoiceTab"
import LogTab from "./logTab/LogTab"
import BillingTab from "./billingTab/BillingTab"


export default function AccountDetails(){
	const [appToShow, setAppToShow] = useState(<ServicesTab />)
	const [currentTab, setCurrentTab] = useState("SERVICES"); 
	const dispatch = useDispatch()
	const [showModal, setShowModal] = useState(false)
	const addressID = useSelector( (state) => state.addressID) 
	const addresslist = useSelector( (state) => state.customerServiceAddresses)
	const accordionItems =  addresslist.map( (address, index) =>{
		return(
			<option value = {address.id}>
				{address.location_name} at {address.street}, {address.city}, {address.state}, {address.zipcode}
			</option>
		)
	})

	///request customer...

	useEffect(()=>{	
		switch(currentTab.toUpperCase()){
			case "SERVICES": 
				setAppToShow(<ServicesTab />)
				break; 
			case "INVOICES": 
				setAppToShow(<InvoiceTab />)
				break; 
			case "BILLING": 
				setAppToShow(<BillingTab />)
				break;
			case "CONTACT": 
				//setAppToShow(<LogTab />)
				break;
			case "ACCOUNT": 
				setAppToShow(<AccountTab />)
				break;
			default:
				//setAppToShow(<>hh </>)
				break;
		}
	}, [ currentTab])
	return(
		<Container >
			<Modal show={showModal} centered static>
				<Modal.Header closeButton onHide={() => setShowModal(false)} >
					<strong> New Address</strong>
				</Modal.Header>
				<Modal.Body>
					<NewAddressForm closeModal={() => setShowModal(false)} />
				</Modal.Body>
			</Modal>
			<Row>
				<Col xs sm md lg = {3} className = "scrollme">	
				
					
					
			
			<Row>
				<hr/>
					<Accordion defaultActiveKey="3" alwaysOpen>
							<Accordion.Item eventKey="3">
								<Accordion.Header>
									Account Details
								</Accordion.Header>
								<Accordion.Body>
									<AccountTab />
								</Accordion.Body>
							</Accordion.Item >
							<BillingTab />
					<Accordion.Item eventKey ="1">
						<Accordion.Header>
							More Addresses
						</Accordion.Header>
						<Accordion.Body>
								<Col sm md lg = {10}>
									<Form.Label> Addresses in this account</Form.Label>
									<Form.Select value= {addressID} onChange = {(event) => {
										dispatch(determineID(event.target.value) ).then((data, err) =>{

										})
									}}>
										{accordionItems}
									</Form.Select>
								</Col>
								<Col>
									<Container>
									<hr/>
									<br/>
									<Button size= "sm" variant="secondary" onClick = { ()=> setShowModal(true)}>
										Add address
									</Button>
									</Container>
								</Col>
						</Accordion.Body>
						</Accordion.Item>
					</Accordion>
			</Row>
							
				</Col>
				<Col xs ms md lg = {8}>
					<ServicesTab />
				</Col>
			</Row>
		</Container>
	)
}