
import { combineReducers } from 'redux';
import stops from './stops/stops'
import testReport from './testReport/testReport'
import invoice from './invoice/invoice.js'
import session from './session/session'; 
import errors from "./errors/errors"
import dates from "./dates/dates"; 
import invoiceList from "./invoiceList/invoiceList"
import testreportlist from "./testreportlist/testreportlist"
//import serviceslist  from "./serviceslist/serviceslist"
import print from "./print/print"
import waterDistrict from "./waterDistrict/waterDistrict"
import printers from "./printers/printers"
import testers from "./testers/testers"
import dispatch from "./dispatch/dispatch"
import results from "./results/results"
import chat from "./chat/chat"
import customer from "./customersnap/customer"
import contact from "./customersnap/contact"
import addresses from "./customersnap/addresses"
import overdue from "./overdue/overdue"
import stoplist from "./stoplist/stoplist"
import waterdistrictlist from "./waterDistrict/waterDistrictList"
import lineitems from "./lineitems/lineitems"
import qbInvoice from "./qbInvoice/qbInvoice"
import reportlist from "./reportlist/reportlist"
import districtlist from "./districtlist/districtlist"
import onlinelist from "./onlinelist/onlinelist"

import customerdetails from "./customer/details"
import customer_reports from "./customer/reports"
import customerServiceAddresses from "./customer/addresses"
import addressAssemblies from "./customer/assemblies"
import customerID from "./customer/cid"
import billingDetails from "./customer/billingDetails"; 
import customerInvoices from "./customer/customerInvoices"
import customer_payments from "./customer/payments"
import logList from "./customer/logList"
import customer_servicelist from "./customer/servicelist"





/*water delivery*/
import deliveryDate from "./waterdelivery/date"
import deliverylist from "./waterdelivery/waterlist"
import deliverytotal from "./waterdelivery/total"
import waterlist from "./waterdelivery/waterdistrictlist"
import waterID from "./waterdelivery/id"

/*test report*/
import reportID from "./testReport/id"
import report_assembly from "./testReport/assembly"
import report_details from "./testReport/details"
import report_tester from "./testReport/tester"

/*invoice handle*/
import invoiceID from "./invoice/invoiceID"
import invoiceTotal from "./invoice/invoiceTotal"
import invoice_billing_detail from "./invoice/invoice_billing_detail"
import invoice_service_address from "./invoice/invoice_service_address"
import invoice_line_items from "./invoice/invoice_line_items"
import invoice_balance from "./invoice/invoice_balance"
import invoice_term_list from "./invoice/invoice_term_list"
import invoice_term_id from "./invoice/invoice_term_id"
import invoice_tax_rate from "./invoice/invoice_tax_rate"

import report_list  from "./home/reportlist.js"


/*scheduling imports*/
import schedule_date from "./scheduling/date"
import schedule_billing from "./scheduling/billingDetail"
import schedule_itemlist from "./scheduling/itemlist"
import schedule_payment from "./scheduling/payment"
import schedule_timeframe from "./scheduling/timeframe"
import schedule_datelist from "./scheduling/datelist"
import schedule_defaultservice from "./scheduling/defaultservicetype"
import schedule_serviceslist from "./scheduling/serviceslist"
import schedule_onsitename from "./scheduling/onsitename"
import schedule_onsitephone from "./scheduling/onsitephone"
import schedule_onsitenote from "./scheduling/onsitenote"
import schedule_onsitegate from "./scheduling/onsitegate"
import schedule_paymentemail from "./scheduling/paymentemail"
import schedule_calendarlist from "./scheduling/calendarlist"
import schedule_addressid from "./scheduling/addressID"
import schedule_total from "./scheduling/overall"


/*home application*/
import home_totalslist from "./home/totalslist"


/*services report*/
import services_report_date from "./services_report/serviceReportDate"
import services_report_list from "./services_report/servicesReport"
import services_report_assemblies from "./services_report/serviceReportAssemblies"
import services_report_contact from "./services_report/serviceReportContact"
import services_report_current_stop from "./services_report/current_stop"
import services_report_current_stop_id from "./services_report/current_stop_id"
import services_report_address from "./services_report/service_address"
import services_water_list from "./services_report/services_water_list"

/*dump app*/
import dump_report_list from "./dump/dump_reports"
import dump_report_water_filter  from "./dump/dump_district_filter"


/*employee management*/
import employee_management_search from "./employees/employeeSearch"
import employee_management_details from "./employees/employeeDetails"
import employee_management_gauges from "./employees/gaugeList"
import employee_management_testerID from "./employees/testerID"
import employee_management_testers  from "./employees/testerList"


/*new stop*/
import newstop_address from "./newstop/address"
import newstop_contact from "./newstop/contact"
import newstop_details from "./newstop/details"
import newstop_services from "./newstop/services"


/*failed reports*/
import failed_list from "./failed/list"
import failed_assembly from "./failed/assembly"
import failed_customer from "./failed/customer"
import failed_report  from "./failed/report"
import failed_parts from "./failed/parts"
import failed_id from "./failed/id"

/*range*/
import start_date from "./home/start_date"
import end_date from "./home/end_date"


/*addressAPP*/
import addressID from "./address/address"	
import address_stoplist from "./address/stoplist"
import address_details from "./address/address_details"
import address_assemblies from "./address/assemblylist"
import schedule_services_list from "./stops/schedule_services_list"


/* invoice builder*/	
import builder_id from "./invoice_builder/builder_id"; 
import builder_service from "./invoice_builder/builder_service"
import builder_billing from "./invoice_builder/builder_details"
import builder_service_details from "./invoice_builder/builder_service_details"
import builder_items from "./invoice_builder/builder_item_list"
import builder_total from "./invoice_builder/total"

/*gauge update*/
import gauge_id from "./gauge/gauge_id"
import gauge_details from "./gauge/gauge_details"


/*rounds app*/
import rounds_date from "./rounds/rounds_date"
import rounds_month from "./rounds/rounds_month"
import rounds_count from "./rounds/rounds_count"
import rounds_list from "./rounds/rounds_list"

export default combineReducers({



	rounds_date, 
	rounds_month, 
	rounds_count, 
	rounds_list, 

	gauge_id, 
	gauge_details,


	builder_total,
	builder_id, 
	builder_service, 
	builder_billing, 
	builder_service_details, 
	builder_items, 
	schedule_total, 
	schedule_services_list,
	addressID, 
	address_stoplist, 
	address_details, 
	address_assemblies, 

	employee_management_testers, 
	employee_management_gauges, 
	employee_management_details, 
	employee_management_search, 
	employee_management_testerID,

	dump_report_list, 
	dump_report_water_filter, 

	stops, 
	testReport, 
	invoice, 
	session, 
	dates, 
	errors, 
	invoiceList,
	testreportlist, 
	//serviceslist,
	print, 
	waterDistrict,
	printers, 
	testers, dispatch,
	results, chat,
	customer, contact,
	addresses, overdue, 
	stoplist, waterdistrictlist, 
	lineitems, qbInvoice, reportlist,
	 districtlist, 
	 onlinelist, 

	 invoiceID, 
	 invoiceTotal,
	 invoice_billing_detail, 
	 invoice_service_address,
	 invoice_line_items ,
	 invoice_balance,
	 invoice_term_list,
	 invoice_term_id,
	 invoice_tax_rate,

	 customerdetails, 
	 customerServiceAddresses, 
	 addressAssemblies, 
	 customerID, 
	 billingDetails, 
	 customerInvoices, 
	 customer_payments,
	 logList, 
	 customer_reports, 

	 deliveryDate, 
	 deliverylist, 
	 deliverytotal,
	 customer_servicelist,
	 waterlist,
	 waterID, 
	 reportID, 
	 report_details, 
	 report_assembly, 
	 report_tester, 

	 schedule_date, 
	 schedule_billing, 
	 schedule_itemlist, 
	 schedule_payment, 
	 schedule_timeframe,
	 schedule_datelist, 
	 schedule_defaultservice, 
	 schedule_serviceslist, 
	 schedule_onsitename, 
	 schedule_onsitephone, 
	 schedule_onsitenote, 
	 schedule_onsitegate, 
	 schedule_paymentemail, 
	 schedule_calendarlist,
	 schedule_addressid,

	 home_totalslist, 


	 services_report_date, 
	 services_report_list, 
	 services_report_assemblies, 
	 services_report_contact, 
	 services_report_current_stop, 
	 services_report_current_stop_id, 
	 services_report_address, 
	 services_water_list, 

	 newstop_address, 
	 newstop_contact, 
	 newstop_details, 
	 newstop_services, 

	 failed_list, 
	 failed_report, 
	 failed_assembly, 
	 failed_customer, 
	 failed_parts, 
	 failed_id, 

	 report_list, 
	 start_date, 
	 end_date

	})