import {
  SET_ROUNDS_COUNT, CLEAR_ROUNDS_COUNT
} from "../../actions/rounds.js";

const _nullCount =  0;
                
export default (state = _nullCount, { type, count }) => {
  Object.freeze(state);
  switch (type) {
    case SET_ROUNDS_COUNT:
      return count;
    case CLEAR_ROUNDS_COUNT: 
      return _nullCount; 
    case "": 
      return _nullCount; 
    default:
      return state;
  }
};