import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Container  from 'react-bootstrap/Container';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import EnteredInvoice from "./EnteredInvoice"

import {
getInvoiceDetails, 
removeItem, 
requestPaymentLink, 
requestInvoicePDF, 
requestInvoices, 
sendToQuickbooks, 
prepaid, 
requestInvoiceTotal,
requestBillingDetail, 
requestServiceAddress, 
requestInvoiceLineItems, 
setInvoiceAsBroken

} from "../../../actions/invoice.js"

import { ErrorBoundary } from "react-error-boundary";

function ErrorMessage(){
	console.log("Something went wrong")
	return(
		<>

		</>
	)
}

export default function InvoiceActions(props){
	const dispatch =  useDispatch(); 
	const [isEntered, setIsEntered] = useState(false)
	const invoiceID = useSelector( (state) => state.invoiceID) 
	const invoiceItems = useSelector( (state) => state.invoice_line_items )
	const termID = useSelector((state) => state.invoice_term_id)
	const [loadingSpinner, setLoadingSpinner] = useState(false)
	const [prepaidSpinner, setPrepaidSpinner] = useState(false)
	const [isPrepaid, setIsPrepaid] = useState(false)
	const [cantSubmit, setCantSubmit] = useState(false)
	const [message, setMessage] = useState("")
	const [alert, setAlert] = useState(false)
	useEffect(()=>{
		setLoadingSpinner(false); 
		setIsEntered(false)
		setIsPrepaid(false)
		setPrepaidSpinner(false)
	}, [invoiceID])

	return(
		
		<>

			<Container>

				<Row>
					<Col>
						<Alert show = {alert} variant= "danger">
							{message}
						</Alert>
						<ButtonGroup  >
							
								<Button
									size = "sm"
									variant="success"
									onClick = { () =>{
										let isResidential = document.querySelector("#isResidential").checked; 
										let dueDate = document.querySelector("#duedate").value

										if( invoiceItems.length <= 0){
											setAlert(true)
										}else{
												setAlert(false)
												setLoadingSpinner(true)
												setIsEntered(false)
												dispatch( sendToQuickbooks(invoiceID, isResidential, dueDate, termID) )
												.then((data) =>{
												
													console.log("Done Entering it in Quickbooks")
													setIsEntered(true)
													setLoadingSpinner(false)
													
												}).catch((e) =>{
													setInvoiceAsBroken( invoiceID ).then((data, err) =>{
														dispatch( requestInvoiceTotal() ).then( 
															(data, err) =>{
																	dispatch(requestInvoices()).then(
																			(data, err)=> {
																				setLoadingSpinner(false)
																				setAlert(true)
																				setMessage(
																				"Something went wrong with this invoice. It will be back here in a few days!")
																				setTimeout(()=>{
																					setAlert(false)
																					setMessage("")
																					props.resetCount(); 
																				}, 5000)
																				
																			}
																		)
																	})

															}
														)
													})
														

												
										}
									}}
									disabled = { isEntered || isPrepaid }
								> 
									Add to quickbooks
								</Button>
						
							<Button
								size = "sm"
								variant="secondary"
								onClick = {
									()=>{
										setPrepaidSpinner(true)
										setIsPrepaid(false)
										dispatch( prepaid( invoiceID) ).then((data, err)=>{
											setPrepaidSpinner(false)
											setIsPrepaid(true)
											console.log("Set as prepaid")
										})
									}
								}
								disabled = { isEntered || isPrepaid }
							> 
								Prepaid 
							</Button>
						</ButtonGroup>
					</Col>
				
					<Col>
						<>
						{
							loadingSpinner ?
								<Spinner/ >
							: 
								<>
									{
										isEntered ?
											<EnteredInvoice />
										: 
											<> </>
									}
								</>

						}
						</>
						<>
						{
							prepaidSpinner ?
								<Spinner />
							: 

								<>
									{ 
										isPrepaid ?
											<>
												PREPAID
											</>
										:
											<> </>
									}
								</>
						}	
						</>		
					</Col>
				</Row>
			</Container>
		</>

	)
}