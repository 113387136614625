import Alert from 'react-bootstrap/Alert';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';
import Offcanvas from 'react-bootstrap/Offcanvas';
import CardGroup from 'react-bootstrap/CardGroup';
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import { getTotalsList  } from "../../../actions/home.js"
import { getTestersStops , getStops} from "../../../actions/scheduling.js"
import {format } from "date-fns"

export default function RoundCard(){
	return(
		
			
			<Card bg="dark" text ="light">
				<Card.Header>	
					<Container>
						<Row>
							<Col sm md lg = {8}>
								<p>
									<strong> Customer Name </strong>
									<br/>
									street, city, state, zipcode
								</p>
								<p>
									<strong> Contact Name </strong>
									<br/>
									Phone #:xxx xxx xxxx
									<br/>
									Email: xxx@xxxx.com
								</p>
							</Col>
							<Col>
								<p>
									<strong> Billing </strong>
									<br/>
									Billing to name
								</p>
								<p>	
									<strong> Follow Up: </strong> ###
								</p>
							</Col>
						</Row>
					</Container>
				</Card.Header>
				<Card.Body className = "expandable">
					<Container>	
						<Row>
							<Col>
								<strong><small> NEW ROUND </small></strong>
							</Col>
						</Row>
						<Row>
							<Col>
								<Container>
								<Form.Check type = "checkbox" label = "Left voicemail"/>
								<Form.Check type = "checkbox" label = "Unable to reach"/>
								<FloatingLabel  size = "sm" label = "Note">
									<Form.Control  size = "sm"/>
								</FloatingLabel>
								<FloatingLabel label = "Follow up date"  size = "sm">
									<Form.Control type="date"  size = "sm"/>
								</FloatingLabel>
								</Container>
							</Col>
						</Row>
						<Row>
						</Row>
					</Container>
				</Card.Body>
				<Card.Footer>
					<Container>
									<Row>
										<Col>
											<ButtonGroup >
												<Button size = "sm" variant="danger">No Follow Up</Button>
									
												<Button size= "sm"variant= "success"> Next round </Button>
											</ButtonGroup>
										</Col>
									</Row>
								</Container>
				</Card.Footer>
			</Card>
				
			
	)
}