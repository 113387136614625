import Alert from 'react-bootstrap/Alert';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';
import Offcanvas from 'react-bootstrap/Offcanvas';
import CardGroup from 'react-bootstrap/CardGroup';
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import { getTotalsList  } from "../../../actions/home.js"
import { getTestersStops , getStops} from "../../../actions/scheduling.js"
import {format } from "date-fns"
import { setRoundsDate, setRoundsMonth, setRoundsCount, setRoundsList} from "../../../actions/rounds.js"

export default function AppFilters(){
	const currentMonth = useSelector((state) => state.rounds_month)
	const currentDate = useSelector((state) => state.rounds_date)
	const currentCount = useSelector((state) => state.rounds_count)
	const dispatch = useDispatch(); 


	const [myDate, setMyDate] = useState(null); 
	const [myMonth, setMyMonth] = useState(null); 
	const [myCount, setMyCount] = useState(null); 

	useEffect(()=>{
		setMyDate(currentDate)
		setMyMonth(currentMonth)
		setMyCount(currentCount)
	}, [])
	useEffect(()=>{
		if(myDate != currentDate){
			dispatch(setRoundsDate(myDate)).then((data, err) =>{

			})
		}
	}, [myDate])

	useEffect(()=>{
		if(myMonth != currentMonth){
			dispatch(setRoundsMonth(myMonth) ).then((data, err) =>{

			})
		}
		
	}, [myMonth])

	useEffect(()=>{
		if( myCount != currentCount){
			dispatch( setRoundsCount(myCount) ).then( (data, err) =>{

			})
		}
	}, [myCount])

	const months = [
			{ abb: "January", number: "1"}, 
			{ abb: "February", number: "2"}, 
			{ abb: "March", number: "3"}, 
			{ abb: "April", number: "4"}, 
			{ abb: "May", number: "5"}, 
			{ abb: "June", number: "6"}, 
			{ abb: "July", number: "7"}, 
			{ abb: "August", number: "8"}, 
			{ abb: "September", number: "9"}, 
			{ abb: "October", number: "10"}, 
			{ abb: "November", number: "11"}, 
			{ abb: "December", number: "12"}, 

		]
	const renderMonths = months.map( (mm)=>{
		return(
				<option value = {mm.number}> {mm.abb} </option>
			)
	})

	return(
		<Container>
			<Row>
				<Col>
					<FloatingLabel label = "Follow up Due">
						<Form.Control 
							type = "date" 
							value = {myDate}
							onChange = { (event) =>  setMyDate(event.target.value) }
							
						/>
					</FloatingLabel>
				</Col>
				<Col>
					<FloatingLabel label = "Month Due">
						<Form.Select value = {myMonth} onChange = { (event)=> setMyMonth(event.target.value)}>
							{renderMonths}
						</Form.Select>
					</FloatingLabel>
				</Col>
				<Col>
					<FloatingLabel label = "Times Contacted">
						<Form.Select value ={myCount} onChange = { (event) => setMyCount(event.target.value) }>
							<option value = "0"> Has not been contacted</option>
							<option value = "1"> 1 </option>
							<option value="2"> 2 </option>
							<option value="3"> 3 </option>
							<option value="4"> 4+ </option>
						</Form.Select>
					</FloatingLabel>
				</Col>
			</Row>
		</Container>
	)

}