import * as server from "../util/roundsServer";


export const SET_ROUNDS_DATE = "SET_ROUNDS_DATE"; 
export const CLEAR_ROUNDS_DATE = "CLEAR_ROUNDS_DATE"
export const SET_ROUNDS_MONTH = "SET_ROUNDS_MONTH"
export const CLEAR_ROUNDS_MONTH = "CLEAR_ROUNDS_MONTH"
export const SET_ROUNDS_COUNT = "SET_ROUNDS_COUNT"
export const CLEAR_ROUNDS_COUNT = "CLEAR_ROUNDS_COUNT"
export const SET_ROUNDS_LIST = "SET_ROUNDS_LIST"
export const CLEAR_ROUNDS_LIST = "CLEAR_ROUNDS_LIST"


const receiveRoundsDate = (date) =>({
	type: SET_ROUNDS_DATE, 
	date
})

const resetRoundsDate = () => ({
	type: CLEAR_ROUNDS_DATE
})

const receiveRoundsMonth = (month) =>({
	type: SET_ROUNDS_MONTH, 
	month
})

const resetRoundsMonth = () => ({
	type: CLEAR_ROUNDS_MONTH
})
const receiveRoundsCount = (count) =>({
	type: SET_ROUNDS_COUNT, 
	count
})
const resetRoundsCount = () => ({
	type: CLEAR_ROUNDS_COUNT
})
const receiveRoundsList = (list) =>({
	type: SET_ROUNDS_LIST, 
	list
})
const resetRoundsList = () => ({
	type: CLEAR_ROUNDS_LIST
})



export const setRoundsDate = (date) => dispatch => {
	return new Promise((resolve, reject) =>{
		dispatch(receiveRoundsDate(date) )
		resolve(); 
	})
}

export const clearRoundsDate = () => dispatch => {
	return new Promise((resolve, reject) =>{
		dispatch(resetRoundsDate() )
		resolve(); 
	})
}

export const setRoundsMonth = (month) => dispatch => {
	return new Promise((resolve, reject) =>{
		dispatch(receiveRoundsMonth(month) )
		resolve(); 
	})
}
export const clearRoundsMonth = () => dispatch => {
	return new Promise((resolve, reject) =>{
		dispatch(resetRoundsMonth() )
		resolve(); 
	})
}
export const setRoundsCount = (count) => dispatch => {
	return new Promise((resolve, reject) =>{
		dispatch(receiveRoundsCount(count) )
		resolve(); 
	})
}

export const clearRoundsCount = () => dispatch => {
	return new Promise((resolve, reject) =>{
		dispatch(resetRoundsCount() )
		resolve(); 
	})
}

export const setRoundsList = (list) => dispatch => {
	return new Promise((resolve, reject) =>{
		dispatch(receiveRoundsList(list) )
		resolve(); 
	})
}
export const clearRoundsList = () => dispatch => {
	return new Promise((resolve, reject) =>{
		dispatch(resetRoundsList() )
		resolve(); 
	})
}


export const requestList = (dueDate, month, count) => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		const response = await server.requestList(dueDate, month, count)
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveRoundsList(JSON.parse(data).list)); 
			resolve()
		}
	})
}

export const requestDetails = (roundID) => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		/*const response = await server.requestList(dueDate, month, count)
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveRoundsList(JSON.parse(data).list)); 
			resolve()
		}*/
	})
}







