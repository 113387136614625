import Alert from 'react-bootstrap/Alert';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';
import Offcanvas from 'react-bootstrap/Offcanvas';
import CardGroup from 'react-bootstrap/CardGroup';
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import { getTotalsList  } from "../../../actions/home.js"
import { getTestersStops , getStops} from "../../../actions/scheduling.js"
import {format } from "date-fns"
import {
	setServicesReportDate, 
	requestServicesReportList, 
	reduceStopID,
	clearStop, 
	cancelStop, 
	requestStopAssemblies, 
requestInvoiceStatus
} from "../../../actions/servicesReport.js"
import {setAddress, setContact, setDetails, setServices, createStop} from "../../../actions/newstop"
import { newCustomerID, createCustomer, associateAddressToCustomer} from "../../../actions/customerApp.js" 
import { determineID } from "../../../actions/addressApp"
import { requestTestReports, requestStops, requestStopTesters, updateTesters} from "../../../actions/addressApp"

import StopOptions from "./StopOptions"
import NewStop from "./newstop/NewStop"


export default function TesterStops(props){
	const [myAssemblies, setMyAssemblies] = useState([])	
	const [myInvoice, setMyInvoice] = useState(null)
	const [hasComment, setHasComment] = useState(false)
	const dispatch = useDispatch()
	const [cardType, setCardType] = useState("secondary")
	const [loader, setLoader] = useState(false)
	const [showBadge, setShowBadge] = useState(false)
	const [tList, setTList] = useState([])

	const renderAssigned = tList.map( (tt) =>{
		return(
			<>
				{tt.tester_name}
				<br/>
			</>
		)
	})
	useEffect(()=>{
		requestStopTesters(props.record.stopID).then((data, err)=>{
			setTList(data)
		})
	}, [])

	useEffect(()=>{
		if(props.record.startTime !=  "08:00" || props.record.endTime !=  "16:00"){
			setShowBadge(true)
		}else{
			setShowBadge(false)
		}
	}, [])
	

	useEffect(() => {
		setLoader(true)
		requestStopAssemblies(props.record.stopID).then((data, err) =>{
			setMyAssemblies(data)
			setLoader(false)
		})

	}, [])


	useEffect(()=>{
		requestInvoiceStatus(props.record.stopID).then((data, err) =>{
			setMyInvoice(data[0])
		})
	},[])


	useEffect(()=>{
		if(props.record.reason != null && props.record.reason != ''){
			setHasComment(true)
		}else{
			setHasComment(false)
		}

	}, [])
	
	useEffect(()=>{
		if( props.record.status.toUpperCase() == "CREATED" || props.record.status.toUpperCase() == "SCHEDULED" || props.record.status.toUpperCase() == "NOT_TESTED"){
			setCardType("secondary")
		}else{
			setCardType("success")
		}
	}, [])

	function myState (current){
		if(current.toUpperCase() == "CREATED" || current.toUpperCase() == "SCHEDULED"){
			return "INCOMPLETE"
		}

		if(current == "PDF_CREATED" || current == "TESTER_APPROVED"){
			return "TESTED"
		}

		return current
	}

	const renderAssemblies = myAssemblies.map((assembly, index) =>{
		let show = true
		return(
			
			<>{
				show ?
				
					
					<p className="redText">
						<strong>{assembly.reason} </strong>
					</p>
						
				:
					<>
					</>
			}
				</>
		)
	})


	return(
		<Card  
			className = {`calendarCard ${cardType}`}
			border={cardType}
			onDoubleClick={
				()=> {
					dispatch( newCustomerID(props.record.accountID) ).then((data, err)=>{
						console.log(props.record.addressID)
						dispatch( determineID (props.record.addressID) ).then((data, err) =>{

						})  
					})
				}

			}>

			<Card.Header className="stopCardHeader">
				
					
					<Row>
					<Col sm md lg={6}>

							<strong>{props.record.location_name}</strong><br/>
							{props.record.street}, <br/>
							{props.record.city}, {props.record.state}, {props.record.zipcode} 
						</Col>
						<Col>
							<strong>Assigned to: </strong> <br/>{renderAssigned}
						</Col>
				</Row>
				{
					showBadge ?
						<Row>
							<Col sm md lg={6}>
								<strong> Timed Stop: </strong>
							</Col>
							<Col>
								<Badge bg="danger"> {format(new Date(`1995-06-26T${props.record.startTime}:00`), 'hh:mm aaaa')}  - {format(new Date(`1995-06-26T${props.record.endTime}:00`), 'hh:mm aaaa')}</Badge>
							</Col>
						</Row>
						:
						<>
						</>

				}

				<Row sm md lg={2}>


					<Col sm md lg={6} >

						<strong>Stop Status: </strong> <br />{myState(props.record.status)}<br />
					</Col>
					<Col>
						<strong>Invoice Status: </strong><br />

						{myInvoice ?
							<>
								{myInvoice.status} <br />
							</>
							:
							<></>
						}


					</Col>


				</Row>
		
			</Card.Header>
			<Card.Body className="servicesCardBody">
					<Container >
					
					<Row>
						<Col >
							{
								loader ?
										<Spinner />
									:
									<>
									  
										{renderAssemblies}
									</>
							}
									
							
						</Col>
				
					</Row>
					<Row>
						<Col>
							<p>
								<strong>	{props.record.stopComment} </strong>
							</p>
						</Col>
					</Row>
					
					</Container>
				
			</Card.Body>
			<Card.Footer>
				<Container>
					<Row>

						<Col>
							<small> stop id: {props.record.stopID}</small>
						</Col>

					</Row>
				</Container>
			</Card.Footer>
			
		</Card>
	)

}